import { UserMenu } from "./UserMenu";
import { NotificationsMenu } from "./NotificationsMenu";
import { Logo } from "@/components/ui/logo";
import { useAuth } from "@/hooks/useAuth";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Menu, Home, Users, Heart, Lightbulb, Bot, Settings, LogOut, Info, Mail, HelpCircle, Shield, FileText, BookOpen } from "lucide-react";
import { Link, useNavigate, useLocation } from "react-router-dom";

export const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (href: string) => {
    if (location.pathname === href) {
      navigate(0);
    } else {
      navigate(href);
    }
  };

  const menuItems = [
    { icon: Home, label: "Home", href: "/" },
    { icon: Users, label: "Friends", href: "/friends" },
    { icon: Heart, label: "Wishlist", href: "/wishlist" },
    { icon: Lightbulb, label: "Recommendations", href: "/recommendations" },
    { icon: Bot, label: "AI Assistant", href: "/ai-assistant" },
    { icon: Settings, label: "Settings", href: "/settings" },
    { icon: LogOut, label: "Logout", href: "#", onClick: logout, className: "text-red-600" }
  ];

  const commonMenuItems = [
    { icon: Info, label: "About", href: "/about" },
    { icon: Mail, label: "Contact", href: "/contact" },
    { icon: HelpCircle, label: "FAQ", href: "/faq" },
    { icon: Shield, label: "Privacy Policy", href: "/privacy-policy" },
    { icon: FileText, label: "Terms of Service", href: "/terms-of-service" },
    { icon: BookOpen, label: "Impressum", href: "/impressum" },
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 h-16 bg-white border-b border-gray-200 z-50 animate-fade-in">
      <div className="container h-full mx-auto px-4">
        <div className="h-full flex items-center justify-between">
          <div className="flex items-center">
            <Logo />
          </div>
          {user && (
            <div className="flex items-center gap-4">
              <NotificationsMenu />
              <div className="hidden lg:block">
                <UserMenu />
              </div>
              <div className="lg:hidden">
                <Sheet>
                  <SheetTrigger asChild>
                    <button className="p-2 rounded-lg bg-white hover:bg-gray-100 transition-colors focus:outline-none active:bg-gray-200">
                      <Menu className="h-6 w-6" color="#9b87f5" />
                    </button>
                  </SheetTrigger>
                  <SheetContent side="right" className="w-64 p-0 bg-white">
                    <div className="flex flex-col h-full bg-gradient-to-b from-white to-[#F1F0FB]">
                      <ScrollArea className="flex-1 py-6">
                        <div className="flex flex-col">
                          <ul className="space-y-1 px-3">
                            {menuItems.map((item) => (
                              <li key={item.label}>
                                <button
                                  onClick={() => item.onClick ? item.onClick() : handleNavigation(item.href)}
                                  className={`w-full flex items-center gap-3 px-4 py-3 text-gray-700 rounded-lg hover:bg-white/50 hover:text-[#9b87f5] transition-all duration-200 group focus:outline-none ${item.className || ''}`}
                                >
                                  <div className="p-2 rounded-lg bg-white/80 shadow-sm group-hover:shadow-md transition-shadow">
                                    <item.icon className="h-5 w-5" />
                                  </div>
                                  <span className="font-medium">{item.label}</span>
                                </button>
                              </li>
                            ))}
                          </ul>

                          <div className="mt-6 pt-6 border-t border-gray-200">
                            <h3 className="px-6 mb-2 text-xs font-semibold text-gray-500 uppercase">Pages</h3>
                            <ul className="space-y-1 px-3">
                              {commonMenuItems.map((item) => (
                                <li key={item.label}>
                                  <button
                                    onClick={() => handleNavigation(item.href)}
                                    className="w-full flex items-center gap-3 px-4 py-2 text-gray-600 rounded-lg hover:bg-white/50 hover:text-[#9b87f5] transition-all duration-200 group focus:outline-none"
                                  >
                                    <item.icon className="h-4 w-4" />
                                    <span className="text-sm">{item.label}</span>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </ScrollArea>
                    </div>
                  </SheetContent>
                </Sheet>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
