import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { useAuth } from "@/hooks/useAuth";
import { useEffect } from "react";
import { resetMobileViewport } from "@/lib/utils";
import { useLanguage } from "@/contexts/LanguageContext";
import { BackgroundImage } from "./BackgroundImage";

export const Layout = ({
  children,
  hideSidebar = false,
  hideNavbar = false,
  hideNavigation = false
}: {
  children: React.ReactNode,
  hideSidebar?: boolean,
  hideNavbar?: boolean,
  hideNavigation?: boolean
}) => {
  const { user } = useAuth();
  const { translate } = useLanguage();

  useEffect(() => {
    const meta = document.querySelector('meta[name="viewport"]');
    if (meta) {
      meta.setAttribute('content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
      );
    }

    let colorMeta = document.querySelector('meta[name="theme-color"]');
    if (!colorMeta) {
      colorMeta = document.createElement('meta');
      colorMeta.setAttribute('name', 'theme-color');
      document.head.appendChild(colorMeta);
    }
    colorMeta.setAttribute('content', '#ffffff');
    return () => {
      if (meta) {
        meta.setAttribute('content',
          'width=device-width, initial-scale=1.0'
        );
      }
      if (colorMeta) {
        colorMeta.remove();
      }
    };
  }, []);

  return (
    <div className="relative min-h-screen bg-[#F1F0FB]/20">
      {!hideNavbar && (
        <div className="fixed top-0 left-0 right-0 z-50">
          <Navbar />
        </div>
      )}
      {!hideSidebar && <Sidebar />}
      <main className={`
        ${!hideNavbar ? 'pt-16' : ''}
        ${!hideSidebar ? 'lg:pl-64' : ''}
        min-h-screen
        pb-24
      `}>
        {children}
      </main>
      <footer className="text-center py-4 text-sm text-[#8E9196] mt-auto">
        <div className="container mx-auto">
          {translate("footer.copyright")}
        </div>
      </footer>
    </div>
  );
};
